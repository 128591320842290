class RoomNotFound extends Error {
  id = null;
  constructor(id) {
    super('Room not found');
    this.id = id;
  }
}

class RoomServerError extends Error {
  constructor() {
    super('Room server error');
  }
}

class RoomExpired extends Error {
  constructor() {
    super('Room expired error');
  }
}

export { RoomNotFound, RoomExpired, RoomServerError };
