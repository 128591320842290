/* eslint-disable no-debugger */
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import axios, { AxiosInstance } from 'axios';
import { getAuthDeferred } from '../utils/firebase';
import {
  Company,
  InviteInfo,
  User,
  Template,
  IDocument,
  Room,
  ApplicationWithTemplate,
} from '@/interfaces/index';
import { messagingServerURL, WORKERS } from './config';
import { RoomExpired, RoomNotFound, RoomServerError } from '../errors';

const {
  JOB_TEMPLATES_WORKER,
  USERS_WORKER,
  ROOMS_WORKER,
  APPLICATIONS_WORKER,
  MAILER_WORKER,
  INTEGRATIONS_WORKER,
} = WORKERS;

class MongoClient {
  firebaseIdToken?: string;

  applicationsWorker: AxiosInstance;
  usersWorker: AxiosInstance;
  roomsWorker: AxiosInstance;
  mailerWorker: AxiosInstance;
  sessionTemplatesWorker: AxiosInstance;
  integrationsWorker: AxiosInstance;
  messagingService: AxiosInstance;

  constructor() {
    this.applicationsWorker = this.createAxiosInstance(APPLICATIONS_WORKER);
    this.usersWorker = this.createAxiosInstance(USERS_WORKER);
    this.roomsWorker = this.createAxiosInstance(ROOMS_WORKER);
    this.mailerWorker = this.createAxiosInstance(MAILER_WORKER);
    this.sessionTemplatesWorker = this.createAxiosInstance(
      JOB_TEMPLATES_WORKER,
      true,
    );
    this.integrationsWorker = this.createAxiosInstance(INTEGRATIONS_WORKER);
    this.messagingService = this.createAxiosInstance(messagingServerURL);
    const auth = getAuthDeferred();
    onAuthStateChanged(auth, async (user) => {
      const token = await user?.getIdToken();
      this.firebaseIdToken = token;

      // Refresh Axios instances with the new token
      if (this.firebaseIdToken) {
        this.updateAxiosInstancesWithToken();
      }
    });
  }

  createAxiosInstance(
    baseURL: string,
    useCustomInterceptor: boolean = true,
  ): AxiosInstance {
    const instance = axios.create({
      baseURL,
      headers: this.firebaseIdToken
        ? { Authorization: `Bearer ${this.firebaseIdToken}` }
        : {},
    });

    if (useCustomInterceptor) {
      instance.interceptors.response.use(undefined, this.createInterceptor());
    }
    return instance;
  }

  createInterceptor() {
    return async (error: any) => {
      if (error.response?.status === 401) {
        const auth = getAuthDeferred();
        const token = await auth.currentUser?.getIdToken(true);
        this.firebaseIdToken = token;
        if (this.firebaseIdToken) {
          this.updateAxiosInstancesWithToken();
          error.config.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
          return axios(error.config);
        }
      }
      return Promise.reject(error);
    };
  }

  updateAxiosInstancesWithToken() {
    // Assuming all Axios instances need to be updated
    this.applicationsWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.usersWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.roomsWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.mailerWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.sessionTemplatesWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.integrationsWorker.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;
    this.messagingService.defaults.headers.Authorization = `Bearer ${this.firebaseIdToken}`;

    // Add others if needed
  }

  getUser = async (token: string): Promise<User | null> => {
    const { data } = await this.usersWorker.get('/hey', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return data;
  };

  getAllAppsAdmin = async ({ page, page_size }) => {
    const { data } = await this.applicationsWorker.get(
      '/secure/admin/completed-apps',
      {
        params: {
          page,
          page_size,
        },
      },
    );
    return data;
  };

  getAllApps = async ({ page, page_size, session_template_id }) => {
    const { data } = await this.applicationsWorker.get(
      '/secure/completed-apps',
      {
        params: {
          page,
          page_size,
          session_template_id,
        },
      },
    );
    return data;
  };

  sendFeedback = async ({
    feedback,
    roomId,
  }: {
    feedback: string;
    roomId: string;
  }) => {
    const { data } = await this.usersWorker.post('/feedback', {
      feedback,
      roomId,
    });

    return data;
  };

  sendRating = async ({
    rating,
    roomId,
  }: {
    rating: number;
    roomId: string;
  }) => {
    const { data } = await this.usersWorker.post('/rating', {
      rating,
      roomId,
    });

    return data;
  };

  findGlobalActiveJobs = async () => {
    const { data } = await this.applicationsWorker.get(
      '/secure/global-positions',
    );

    return data;
  };

  getOrgMembers = async (companyId: string) => {
    const { data } = await this.usersWorker.get('/members', {
      params: {
        companyId,
      },
    });

    return data as IDocument<User>[];
  };

  getTemplates = async (visibilityFilter: any) => {
    const { data: sessionTemplates } = await this.sessionTemplatesWorker.get(
      '/secure/all',
      {
        params: {
          visibilityFilter: visibilityFilter.join(','),
        },
      },
    );

    return sessionTemplates;
  };

  getPublicPositions = async (boardDomain: string) => {
    const { data: sessionTemplates } = await this.sessionTemplatesWorker.get(
      '/positions/all',
      {
        params: {
          boardDomain,
        },
      },
    );

    return sessionTemplates;
  };

  inviteUserToCompany = (email: string, companyId: string) =>
    this.usersWorker.post('/user/invite-to-org', {
      email,
      companyId,
    });

  updateUser = async (userId: string, data: any) => {
    await this.usersWorker.put('/' + userId, data);
  };

  getInterviewRequests = async () => {
    // const { data } = await this.roomsApiClient.get('/interview-requests', {});
    // return data;
  };

  getCurrentInterviews = async () => {
    // const { data } = await this.roomsApiClient.get('/current-interviews', {});
    // return data;
  };

  decliceInterviewRequest = async ({ urlToken }) => {
    // const { data } = await this.roomsApiClient.post('/decline-request', {
    //   urlToken,
    // });
    // return data;
  };

  dismissNotification = async (id: string) => {
    // await db.collection('notifications').doc(id).delete();
    // return id;
  };

  getCompany = async (id: string): Promise<Company | null> => {
    const { data } = await this.usersWorker.get(`/${id}/company`);
    return data;
  };

  getCompanyByBoardDomain = async (boardDomain: string): Promise<Company> => {
    const { data } = await this.usersWorker.get(`/companies`, {
      params: {
        boardDomain,
      },
    });

    return data;
  };

  inviteInfo = async (id: string): Promise<InviteInfo | null> => {
    const { data } = await this.usersWorker.get('/user/invite', {
      params: {
        urlToken: id,
      },
    });

    return data;
  };

  getRoomInvitation = async ({ id }: { id: string }) => {
    const { data } = await this.mailerWorker.get(`/scheduled/${id}`);

    return data;
  };

  async joinUserByInviteToken(userId: string, companyId, inviteToken: string) {
    const { data } = await this.usersWorker.post('/user/join-org', {
      userId,
      companyId,
      urlToken: inviteToken,
    });
    const inviteInfo = await this.inviteInfo(inviteToken);
    return inviteInfo;
  }

  createUser = async (userId: string, data: any) => {
    await this.usersWorker.post('/', {
      userId,
      value: data,
    });
  };

  publishJob = async (templateId: string, template: any) => {
    await this.sessionTemplatesWorker.put(
      `/secure/publish-session-template/${templateId}`,
    );

    return true;
  };

  closeJob = async (templateId: string) => {
    await this.sessionTemplatesWorker.put(
      `/secure/update-session-template/${templateId}`,
      {
        visibility: 'closed',
      },
    );

    return true;
  };

  openJob = async (templateId: string) => {
    await this.sessionTemplatesWorker.put(
      `/secure/update-session-template/${templateId}`,
      {
        visibility: 'open',
        lastPublishedDate: new Date(),
      },
    );

    return true;
  };

  updateTemplate = async (templateId: string, template: any) => {
    if (!template.description) {
      template.description = JSON.stringify({
        root: {
          children: [
            {
              children: [],
              direction: null,
              format: '',
              indent: 0,
              type: 'paragraph',
              version: 1,
            },
          ],
          direction: null,
          format: '',
          indent: 0,
          type: 'root',
          version: 1,
        },
      });
    }
    await this.sessionTemplatesWorker.put(
      `/secure/update-session-template/${templateId}`,
      template,
    );

    return true;
  };

  createTemplate = async (userId: string, companyId: string, template: any) => {
    const { data: newTemplate } = await this.sessionTemplatesWorker.post(
      '/secure/create-session-template',
      {
        userId,
        companyId: companyId,
        template,
      },
    );

    return newTemplate as IDocument<Template>;
  };

  deleteTemplate = async (templateId: string) => {
    const { data } = await this.sessionTemplatesWorker.delete(
      '/secure/delete-session-template/' + templateId,
    );

    return true;
  };

  getTemplate = async (templateId: string): Promise<IDocument<Template>> => {
    const { data: newTemplate } = await this.sessionTemplatesWorker.get(
      `/${templateId}`,
    );

    return newTemplate;
  };

  getMeeting = async ({ publicId }: { publicId: string }): Promise<Room> => {
    try {
      //# Rename this
      console.log('getMeeting');
      const { data } = await this.roomsWorker.get(`/meeting/` + publicId, {});

      return data;
    } catch (e) {
      console.log(e);
      if (!e.response || !e.response.data) {
        throw new RoomServerError();
      }
      if (e.response && e.response.data.code === 'ROOM_NOT_FOUND') {
        throw new RoomNotFound(publicId);
      } else if (e.response && e.response.data.code === 'ROOM_EXPIRED') {
        throw new RoomExpired();
      }
      throw new RoomServerError();
    }
  };

  validateUserField = async (field: string, value: any) =>
    this.usersWorker.get('/users/isValidField', {
      params: {
        field,
        value: value.trim(),
      },
    });

  validatePublicCompany = async (value: any) => {
    const { data } = await this.usersWorker.get(
      '/companies/domain_check/' + value,
    );
    if (data.message === 'domain-exists') {
      return false;
    }
    return true;
  };

  createAccount = async (email: string, password: string, data: any) => {
    // Firebase User entity creation for authorization
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );

    const userId = userCredentials.user.uid;
    await this.createUser(userId, data);
    return { userId };
  };

  createAccountByInvite = async (
    email: string,
    password: string,
    data: any,
    company: Company,
  ) => {
    const { userId } = await this.createAccount(email, password, {
      ...data,
      companyId: company._id,
    });
  };

  saveCompanyInfo = async ({ values }: { values: string }) => {
    await this.usersWorker.put('/user/update-company', { values });
  };

  async getAvailabilityByDuration({
    date,
    duration,
    inviteToken,
  }: {
    date: string;
    duration: number;
    inviteToken: string;
  }) {
    const { data } = await this.mailerWorker.get('/get-availability', {
      params: {
        date,
        duration,
        inviteToken,
      },
    });
    return data.availableDates;
  }

  sendTrackEvent = async ({
    evtName,
    appId,
    roomId,
  }: {
    evtName: string;
    appId?: string;
    roomId?: string;
  }) => {
    const doc = await this.applicationsWorker.post('/tk', {
      evtName,
      appId,
      roomId,
    });

    return doc.data;
  };

  createTestTask = async ({
    sessionTemplateId,
  }: {
    sessionTemplateId: string;
  }) => {
    const doc = await this.messagingService.post('/api/user/create-test-task', {
      sessionTemplateId,
    });
    return doc.data;
  };

  playPresentation = async ({
    sessionTemplateId,
  }: {
    sessionTemplateId: string;
  }) => {
    const doc = await this.messagingService.post(
      '/api/user/play-presentation',
      {
        sessionTemplateId,
      },
    );
    return doc.data;
  };

  getRandomTopic = async ({ type }: { type: string }) => {
    const doc = await this.messagingService.post('/api/public/random-topic', {
      type,
    });
    return doc.data.topic.replace(/"/g, '').trim();
  };

  createSession = async ({
    roomId,
    appId,
    sessionName,
  }: {
    roomId: string;
    appId: string;
    sessionName: string;
  }) => {
    const doc = await this.messagingService.post('/api/user/create-session', {
      roomId,
      appId,
      sessionName,
    });
    return doc.data;
  };

  generateJD = async ({
    sessionTitle,
    industry,
    tone,
  }: {
    sessionTitle: string;
    industry: string;
    tone: string;
  }) => {
    const doc = await this.messagingService.post(
      '/api/user/generate-jd',
      {
        sessionTitle,
        industry,
        tone,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.firebaseIdToken,
        },
      },
    );
    return doc.data;
  };

  createCompanyWithUser = async (userId: string, fields: any) => {
    const { data } = await this.usersWorker.post('/with-company', {
      userId,
      value: fields,
    });
    return data.user;
  };

  async findActivePositions() {
    const { data } = await this.roomsWorker.get('/secure/open-positions');

    return data;
  }

  async findCandidateSummary(roomId: string) {
    const { data } = await this.roomsWorker.get('/summary/' + roomId);

    return data;
  }

  getPublicTemplate = async (publicId: string): Promise<any | null> => {
    const { data } = await this.sessionTemplatesWorker.get(
      `/public/${publicId}`,
    );

    return data;
  };

  getPublicPresentation = async (publicId: string): Promise<any | null> => {
    const { data } = await this.sessionTemplatesWorker.get(
      `/public/presentation/${publicId}`,
    );

    return data;
  };

  async findActiveInterviews() {
    const { data } = await this.roomsWorker.get('/secure/my-interviews');

    return data;
  }

  async findAllCandidates({ page_size, page, session_template_id, status }) {
    if (status === 'other') {
      const { data } = await this.applicationsWorker.get('/secure/all', {
        params: {
          page_size,
          page,
          templateId: session_template_id,
        },
      });

      return data;
    }
    const { data } = await this.roomsWorker.get('/secure/candidates/all', {
      params: {
        page_size,
        page,
        session_template_id: session_template_id,
      },
    });

    return data;
  }

  async getCandidateSummary(summaryId: string) {
    const { data } = await this.roomsWorker.get('/secure/summary/' + summaryId);

    return data;
  }

  async getEnabledIntegrations() {
    const { data } = await this.integrationsWorker.get('/enabled-integrations');

    return data;
  }

  async generateAPIKey(domains: string[]) {
    const { data } = await this.integrationsWorker.post('/generate-key', {
      domains,
      integrationType: 'custom',
    });

    return data;
  }

  async generateJotformScheduler(
    publicId: string,
    {
      email,
      firstName,
      lastName,
    }: { email: string; firstName: string; lastName: string },
  ) {
    const { data } = await this.messagingService.post(
      '/jotform-submission/' + publicId,
      {
        email,
        firstName,
        lastName,
      },
    );

    return data;
  }

  async retrieveEnabledIntegration(integrationType: string) {
    const { data } = await this.integrationsWorker.get(
      '/enabled-integrations',
      {
        params: {
          integrationType,
        },
      },
    );

    return data;
  }

  async getCustomKeys() {
    const { data } = await this.integrationsWorker.get('/custom-keys');

    return data;
  }

  async generateAPIKeyVendor(integrationType: string) {
    const { data } = await this.integrationsWorker.post('/generate-key', {
      integrationType,
    });

    return data;
  }

  async initJobIntegration(integrationType: string, sessionTemplateId: string) {
    const { data } = await this.integrationsWorker.post(
      '/init-integration/' + sessionTemplateId,
      {
        integrationType,
      },
    );

    return data;
  }

  async getGreenhouseJobIntegration(sessionTemplateId: string) {
    const { data } = await this.integrationsWorker.get(
      '/greenhouse-job/' + sessionTemplateId,
    );
    if (!data.settings || !data.settings.greenhouseJobId) {
      return null;
    }

    return data.settings.greenhouseJobId;
  }

  async initGreenhouseJobIntegration(
    sessionTemplateId: string,
    greenhouseJobId: string,
  ) {
    const { data } = await this.integrationsWorker.post(
      '/init-integration-greenhouse-job/' + sessionTemplateId,
      {
        greenhouseJobId,
      },
    );

    return data;
  }

  async deleteJobIntegration({
    formId,
    sessionTemplateId,
    apiKey,
  }: {
    formId: string;
    sessionTemplateId: string;
    apiKey: string;
  }) {
    const { data } = await this.integrationsWorker.delete(
      `/delete-integration`,
      {
        data: {
          formId,
          sessionTemplateId,
          apiKey,
          integrationType: 'jotform',
        },
      },
    );

    return data;
  }
  async saveApiKey({
    integrationType,
    apiKey,
  }: {
    integrationType: string;
    apiKey: string;
  }) {
    const { data } = await this.integrationsWorker.post('/save-key', {
      integrationType,
      apiKey,
    });

    return data;
  }

  async saveIntegrationSettings(
    integrationType: string,
    { settings, sessionTemplateId }: any,
  ) {
    const { data } = await this.integrationsWorker.post('/save-settings', {
      integrationType,
      settings,
      sessionTemplateId,
    });

    return data;
  }

  deleteJobApplicants = async (appIds: string[]) => {
    await this.applicationsWorker.delete('/secure/delete-applicants', {
      data: {
        appIds,
      },
    });
  };

  getPracticeJobs = async ({ limit }: { limit: number }) => {
    const { data: sessionTemplates } = await this.sessionTemplatesWorker.get(
      '/positions/jobs',
      {
        params: {
          limit,
        },
      },
    );

    return sessionTemplates;
  };

  getGreenhouseJobs = async ({ companyId }: { companyId: string }) => {
    const { data: sessionTemplates } = await this.messagingService.post(
      '/greenhouse-jobs',
      {
        companyId,
      },
    );

    return sessionTemplates;
  };

  downloadRecordingFile = async (roomId: string) => {
    const { data } = await this.applicationsWorker.post(
      '/secure/download-recording',
      {
        roomId,
      },
    );

    return data;
  };

  async getSub() {
    const { data } = await this.usersWorker.get(`/user/sub`);
    return data;
  }
}

export default new MongoClient();
