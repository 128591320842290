import { getAuth } from 'firebase/auth';
import { ref, getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyCQVL3KiR44wiJjkufqgE0L6uQGVoda6r0',
  authDomain: 'greetai-auth.firebaseapp.com',
  databaseURL: 'https://greetai-auth-default-rtdb.firebaseio.com',
  projectId: 'greetai-auth',
  storageBucket: 'greetai-auth.appspot.com',
  messagingSenderId: '706004820854',
  appId: '1:706004820854:web:c531d8b4091ad98c2c9057',
  measurementId: 'G-TFCMFB82ZM',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const getApplicationFilesRef = () => {
  const storage = getStorage(app, 'gs://greetai-auth.appspot.com');

  const storageRef = ref(storage);

  const userImagesRef = ref(storageRef, 'images');
  const applicationFiles = ref(storageRef, 'application-files');

  return { userImagesRef, applicationFiles };
};

export const getAuthDeferred = () => {
  return getAuth(app);
};
