import { useRecoilState, useRecoilValue } from 'recoil';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { getAuthDeferred } from '@/utils/firebase';
import { GoogleAuthProvider } from 'firebase/auth';
import {
  authUser,
  authInitializeTracker,
  authenticatingState,
} from '../state/auth';
import api from '../api';
import { Company, User, UserRoles } from '../interfaces';
import { toast } from 'react-hot-toast';
import { getDefaultURLFromUserRole } from '@/utils/user';
import { isIndividual } from '@/utils/misc';
import { openConfirmModal } from '@mantine/modals';

type Handler = {
  onSuccess: (creds: any) => void;
  onError: (error: any) => void;
};

type FieldValues = {
  email: string;
  password: string;
};

function useUser() {
  const user = useRecoilValue(authUser);
  const isInitialized = useRecoilValue(authInitializeTracker);
  const [isAuthenticating, setIsAuthenticating] =
    useRecoilState(authenticatingState);
  const [isOnboarding, setIsOnboarding] = useState(false);

  useEffect(() => {
    if (user?.role === UserRoles.COMPANY_ADMIN && isIndividual) {
      openConfirmModal({
        title: 'Wrong website',
        children: 'Looks like have company account registered for that email.',
        centered: true,
        overlayProps: { backgroundOpacity: 0.99, color: 'black' },
        labels: {
          confirm: 'Navigate to dashboard',
          cancel: 'Logout',
        },
        confirmProps: { color: 'ocean-blue' },
        onCancel: () => {
          logout({
            onSuccess: () => {},
            onError: () => {},
          });
        },
        onClose: () => {
          logout({
            onSuccess: () => {},
            onError: () => {},
          });
        },
        onConfirm: () => {
          window.location.href = 'https://app.greetai.co';
        },
      });
    } else if (user?.role === UserRoles.INDIVIDUAL && !isIndividual) {
      openConfirmModal({
        title: 'Wrong website',
        children:
          'Looks like you have an practice account registered for that email.',
        centered: true,
        overlayProps: { backgroundOpacity: 0.99, color: 'black' },
        labels: {
          confirm: 'Navigate to skills.greetai.co',
          cancel: 'Logout',
        },
        confirmProps: { color: 'ocean-blue' },
        onCancel: () => {
          logout({
            onSuccess: () => {},
            onError: () => {},
          });
        },
        onClose: () => {
          logout({
            onSuccess: () => {},
            onError: () => {},
          });
        },
        onConfirm: () => {
          window.location.href = 'https://skills.greetai.co';
        },
      });
    }
  }, [user]);

  const handleAccountCreation = async ({
    inviter,
    email,
    password,
    meta,
  }: {
    inviter?: Company;
    email: string;
    password: string;
    meta: any;
  }) => {
    setIsOnboarding(true);
    if (inviter) {
      await api.createAccountByInvite(email, password, meta, inviter);
    } else {
      await api.createAccount(email, password, meta);
    }
    setIsOnboarding(false);
  };

  const createCompanyAccount = (
    email: string,
    password: string,
    data: any,
    callback: Handler,
  ) => {
    setIsOnboarding(true);
    const auth = getAuthDeferred();
    if (!password) {
      const userCreds = auth.currentUser;
      api.createCompanyWithUser(userCreds.uid, data).then(async (e) => {
        window.location.href = getDefaultURLFromUserRole(data.accountType);
      });
      return;
    }
    if (!email) {
      callback.onError('Email is required');
      return;
    }
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredentials) => {
        await sendEmailVerification(userCredentials.user);
        return api.createCompanyWithUser(userCredentials.user.uid, data);
      })
      .then(async (e) => {
        callback.onSuccess(e);
        await login(
          { email, password },
          {
            onSuccess: () => {
              window.location.href = getDefaultURLFromUserRole(
                data.accountType,
              );
            },
            onError: (e) => {
              console.log(e);
            },
          },
        );
      })
      .catch(callback.onError)
      .finally(() => {
        setIsOnboarding(false);
      });
  };

  const login = async ({ email, password }: FieldValues, callback: Handler) => {
    const auth = getAuthDeferred();

    setIsAuthenticating(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(callback.onSuccess)
      .catch((e) => {
        callback.onError(e);
        setIsAuthenticating(false);
      });
  };

  const loginGoogle = ({ isIndividual }) => {
    const auth = getAuthDeferred();
    setIsAuthenticating(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        if (isIndividual) {
          // createCompanyAccount(
          //   result.user.email,
          //   '',
          //   {
          //     ...values,
          //     firstName: googleAuth.firstName,
          //     lastName: googleAuth.lastName || '',
          //     profileImage: '',
          //   },
          //   {
          //     onSuccess: () => {
          //       console.log('success');
          //     },
          //     onError: (e) => {
          //       console.log('error', e);
          //     },
          //   },
          // );
        }
        // IdP data available using getAdditionalUserInfo(result)
      })
      .catch((error) => {
        setIsAuthenticating(false);
        toast.dismiss('auth-status');
      });
  };

  const logout = async (callback: Handler) => {
    const auth = getAuthDeferred();

    auth.signOut().then(callback.onSuccess).catch(callback.onError);
  };

  return {
    user,
    createCompanyAccount,
    handleAccountCreation,
    login,
    logout,
    loginGoogle,
    isInitialized,
    isOnboarding,
    isAuthenticating,
    authUser: user as User,
  };
}

export default useUser;
