export default function invariant(expr: any, msg: string): asserts expr {
  if (!expr) {
    throw new Error(msg);
  }
}

export function addProtocol(url) {
  if (!url) return;
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }
  return url;
}

export const isIndividual = import.meta.env.VITE_APP_TYPE === 'skills';

export const tags = (() => {
  let title = 'GreetAI';
  let description = '';
  let keywords = '';
  if (isIndividual) {
    title = 'GreetAI — Practice interviews with AI';
    keywords =
      'practice,interview,practice ai interview,interview feedback,expressions,practice interview,interview simulator,interview tool,interview training,interview coach,interview questions,prepare for job interview,ai interview,ai interview questions,ai interview practice,ai interview feedback,ai interview preparation,ai interview simulator,ai interview tool,ai interview training,ai interview coach,ai interview software,ai interview platform';
    description =
      'greetai.co - Simply generate interview and get instant feedback on your answers and expressions';
  } else {
    title = 'GreetAI — Screen candidates faster';
    description =
      'Evaluate applicants and ensure faster time-to-hire with AI which sounds just like a human';
    keywords =
      'ai screening,ats tool,applicant tracking system,candidate screening,recruitment automation,hiring software,ai recruitment,talent acquisition,resume screening,hiring process,recruitment tool,ai hiring,job application software,automated hiring,recruitment platform,ai talent screening,ai hiring tool,recruitment management,ai candidate evaluation';
  }

  return {
    title,
    keywords,
    description,
  };
})();
