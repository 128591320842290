import { User, IDocument, UserRoles } from '@/interfaces';

const getRoleByUserLevel = (level: string) => {
  if (level === UserRoles.COMPANY_ADMIN) {
    return 'Head of HR Department';
  } else if (level === UserRoles.CANDIDATE) {
    return 'Member';
  }
  return 'Recruiter';
};

const getDefaultURLFromUserRole = (role: string) => {
  if (role === UserRoles.COMPANY_ADMIN) {
    return '/dashboard';
  } else if (role === UserRoles.INDIVIDUAL) {
    return '/sessions';
  }
  return '/';
};

const mapMemberToDriver = ({
  firstName,
  lastName,
  id,
  role,
}: IDocument<User>) => {
  return {
    image:
      'https://i.pravatar.cc/300?u=a342581f4e2902670' +
      Math.floor(Math.random() * 1000) +
      'd',
    label: firstName + ' ' + lastName,
    value: id,
    description: getRoleByUserLevel(role),
  };
};

export { getRoleByUserLevel, mapMemberToDriver, getDefaultURLFromUserRole };
