import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { PageShell } from './PageShell';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://2f53e70ed758a7524a1d2567ee2b3406@o4506526616453120.ingest.sentry.io/4506559055659008',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  enabled: process.env.NODE_ENV === 'production',
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let root;
async function render(pageContext) {
  const { Page, pageProps } = pageContext;
  const page = (
    <HelmetProvider>
      <PageShell pageContext={pageContext}>
        <Page {...pageProps} />
      </PageShell>
    </HelmetProvider>
  );

  const container = document.getElementById('react-root');

  // SPA
  if (container.innerHTML === '' || !pageContext.isHydration) {
    if (!root) {
      root = ReactDOM.createRoot(container);
    }
    root.render(page);
    // SSR
  } else {
    root = ReactDOM.hydrateRoot(container, page);
  }
}

export { render as onRenderClient };
